<template>
      
<!-- This example requires Tailwind CSS v2.0+ -->

<div class=" bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 shadow">
  <div class="md:flex md:items-center md:justify-between md:space-x-5">
    <div class="flex items-start space-x-5">
      <div class="flex-shrink-0">
        <div class="relative">
          <img v-if="logins.userdata.avatar" class="h-16 w-16 rounded-full" :src="logins.userdata.avatar" alt="">
          <img v-if="!logins.userdata.avatar" class="h-16 w-16 rounded-full" src="https://www.gravatar.com/avatar/" alt="">
          <span class="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"></span>
        </div>
      </div>
      <!--
        Use vertical padding to simulate center alignment when both lines of text are one line,
        but preserve the same layout if the text wraps without making the image jump around.
      -->
      <div class="pt-1.5">
        <h1 v-if="logins" class="text-2xl text-left font-bold text-gray-900">{{ logins.userdata.name }}</h1>
        <p class="text-sm text-left font-medium text-gray-500">{{ logins.userdata.email }}</p>
      </div>
    </div>
    <div v-if="logins.userdata" class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
      <router-link :to="{ name: 'NewLoginUuid', params: { uuid: logins.userdata.uuid }}" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        Opret login
      </router-link>
    </div>
  </div>
</div>
<div class="pt-8 text-left">
  <div class=" max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <h2 class="text-lg leading-6 font-medium text-gray-900">Sidste 30 dage</h2>
    <div>
      <dl class="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <div class="px-4 py-5 sm:p-6">
          <dt class="text-base font-normal text-gray-900">
            Samlede logins
          </dt>
          <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
              71,897
              <span class="ml-2 text-sm font-medium text-gray-500">
                fra 70,946
              </span>
            </div>

            <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only">
                Forøget med,
              </span>
              12%
            </div>
          </dd>
        </div>

        <div class="px-4 py-5 sm:p-6">
          <dt class="text-base font-normal text-gray-900">
            Genbrugte logins
          </dt>
          <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
              58.16%
              <span class="ml-2 text-sm font-medium text-gray-500">
                from 56.14%
              </span>
            </div>

            <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only">
                Forøget med
              </span>
              2.02%
            </div>
          </dd>
        </div>

        <div class="px-4 py-5 sm:p-6">
          <dt class="text-base font-normal text-gray-900">
            Svage logins
          </dt>
          <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
              24.57%
              <span class="ml-2 text-sm font-medium text-gray-500">
                from 28.62%
              </span>
            </div>

            <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fill-rule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only">
                Decreased by
              </span>
              4.05%
            </div>
          </dd>
        </div>

        <!-- eslint-disable -->
        <div v-if="isLoading.overview" v-for="n in 3" :key="n" class="px-4 py-5 sm:p-6">
          <dt class="text-base font-normal text-gray-900">
            <div class="grid grid-cols-2 h-5 gap-1 w-full">
              <div class="col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
            </div>
          </dt>
        </div>
        <!-- eslint-enable -->

      </dl>
    </div>
  </div>

  <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
    Logins
  </h2>

  <!-- Activity table (small breakpoint and up) -->
  <div class="hidden sm:block pt-5">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Login sted
                </th>
                <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Username
                </th>
                <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sikkerhedsniveau
                </th>
              </tr>
            </thead>
            
            <!-- Viser sig kun når den fetcher/henter data fra api'en -->
            <tbody v-if="isLoading.table" class="bg-white divide-y divide-gray-200">
              <tr v-for="(n) in 4" :key="n" class="bg-white">
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div class="flex">
                    <a class="group inline-flex space-x-2 truncate text-sm w-full">
                      <div class="grid grid-cols-10 h-5 gap-1 w-full">
                        <div class=" col-span-1 rounded-sm bg-gray-200 animate-pulse"></div>
                        <div class=" col-span-3 rounded-sm bg-gray-200 animate-pulse"></div>
                        <div class=" col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
                      </div>
                    </a>
                  </div>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <div class="grid grid-cols-2 h-5 gap-1 w-full">
                    <div class="col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
                  </div>
                </td>
                <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                  <div class="grid grid-cols-2 h-5 gap-1 w-full">
                    <div class="col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="logins"  class="bg-white divide-y divide-gray-200">
              <tr class="bg-white" v-for="(login, idx) in logins.logins" :key="login">
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div class="flex">
                    <a href="#" class="group inline-flex space-x-2 truncate text-sm">
                      <img :src="'https://www.google.com/s2/favicons?domain=' + login.website" class="flex-shrink-0 h-5 w-5" alt="">
                      <p class="text-gray-500 truncate group-hover:text-gray-900">
                        {{ login.website }}
                      </p>
                    </a>
                  </div>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <div v-on:click="copyToClickboard(idx, 'username')" class="flex">
                    <a href="#" class="group inline-flex space-x-2 truncate text-sm mx-auto">
                    {{login.username}}
                    <svg class="flex-shrink-0 h-5 w-5 ml-2 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    </a>
                  </div>
                </td>

                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <span v-if="Number.isInteger(login.password_strength) && login.password_strength < 1" class="inline-flex items-center mx-auto px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    Meget svagt
                  </span>
                  <span v-else-if="login.password_strength == 2" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                    Mellem
                  </span>
                  <span v-else-if="login.password_strength == 3" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    Godt
                  </span>
                  <span v-else-if="login.password_strength == 4" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Højt
                  </span>
                  <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    ?
                  </span>
                </td>
              </tr>

              <!-- More items... -->
            </tbody>
          </table>
          <!-- Pagination -->
          <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
            <div class="hidden sm:block ">
              <p class="text-sm text-gray-700">
                Viser
                <span v-if="this.logins.logins" class="font-medium">{{this.logins.logins.length}}</span>
                resultater
              </p>
            </div>
            <div class="flex-1 flex justify-between sm:justify-end">
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import axios from 'axios';


export default {

  data() {
    return {
      grettingMessage: null,
      logins: null,
      errors: [],
      isLoading: {
        overview: false,
        table: true,
      },
    } 
  },

    created() {
      this.getUserLogins(this.$route.params.uuid);
    },

    beforeRouteUpdate(to, from, next) {
      this.getUserLogins(to.params.uuid, from, next)

    },
    beforeRouteLeave(to, from, next) {
      this.getUserLogins(to.params.uuid, from, next)
    },

    methods: {
        // Fetches user when the component is reloaded.
        async getUserLogins(uuid, from, next) {
            try {
            const response =  await axios.get("http://localhost:8000/api/user/" +  uuid)
            this.logins = response.data
            // console.log(response.data);
            this.isLoading.table = false
            next()
            } catch (err) {
            this.errors.push(err)
            }
        },
        fetchData(response) {
        this.user = response.data;
    },
    }
}
</script>