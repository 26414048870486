<template>
  

<main class="bg-red-100 flex-1 relative pb-8 z-0 overflow-y-auto">

      <user-menu/>

      <div class="mt-8 text-left">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-lg leading-6 font-medium text-gray-900">Sidste 30 dage</h2>
          <div>
            <dl class="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div class="px-4 py-5 sm:p-6">
                <dt class="text-base font-normal text-gray-900">
                  Samlede logins
                </dt>
                <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                    71,897
                    <span class="ml-2 text-sm font-medium text-gray-500">
                      fra 70,946
                    </span>
                  </div>

                  <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                    <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">
                      Forøget med,
                    </span>
                    12%
                  </div>
                </dd>
              </div>

              <div class="px-4 py-5 sm:p-6">
                <dt class="text-base font-normal text-gray-900">
                  Genbrugte logins
                </dt>
                <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                    58.16%
                    <span class="ml-2 text-sm font-medium text-gray-500">
                      from 56.14%
                    </span>
                  </div>

                  <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                    <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">
                      Forøget med
                    </span>
                    2.02%
                  </div>
                </dd>
              </div>

              <div class="px-4 py-5 sm:p-6">
                <dt class="text-base font-normal text-gray-900">
                  Svage logins
                </dt>
                <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                    24.57%
                    <span class="ml-2 text-sm font-medium text-gray-500">
                      from 28.62%
                    </span>
                  </div>

                  <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                    <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path fill-rule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">
                      Decreased by
                    </span>
                    4.05%
                  </div>
                </dd>
              </div>

              <!-- eslint-disable -->
              <div v-if="isLoading.overview" v-for="n in 3" :key="n" class="px-4 py-5 sm:p-6">
                <dt class="text-base font-normal text-gray-900">
                  <div class="grid grid-cols-2 h-5 gap-1 w-full">
                    <div class="col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
                  </div>
                </dt>
              </div>
              <!-- eslint-enable -->

            </dl>
          </div>
        </div>

        <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          Logins
        </h2>

        <!-- Activity table (small breakpoint and up) -->
        <div class="hidden sm:block pt-5">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Login sted
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Username
                      </th>
                      <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                        Adgangskode
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sikkerhedsniveau
                      </th>
                    </tr>
                  </thead>
                  
                  <!-- Viser sig kun når den fetcher/henter data fra api'en -->
                  <tbody v-if="isLoading.table" class="bg-white divide-y divide-gray-200">
                    <tr v-for="(n) in 4" :key="n" class="bg-white">
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div class="flex">
                          <a class="group inline-flex space-x-2 truncate text-sm w-full">
                            <div class="grid grid-cols-10 h-5 gap-1 w-full">
                              <div class=" col-span-1 rounded-sm bg-gray-200 animate-pulse"></div>
                              <div class=" col-span-3 rounded-sm bg-gray-200 animate-pulse"></div>
                              <div class=" col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
                            </div>
                          </a>
                        </div>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <div class="grid grid-cols-2 h-5 gap-1 w-full">
                          <div class="col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
                        </div>
                      </td>
                      <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                        <div class="grid grid-cols-2 h-5 gap-1 w-full">
                          <div class="col-span-2 rounded-sm bg-gray-200 animate-pulse"></div>
                        </div>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <div class="grid grid-cols-3 h-5 gap-1 w-full">
                          <div class="col-span-3 rounded-sm bg-gray-200 animate-pulse"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tbody  class="bg-white divide-y divide-gray-200">
                    <tr class="bg-white" v-for="(login, idx) in logins" :key="login">
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div class="flex">
                          <a href="#" class="group inline-flex space-x-2 truncate text-sm">
                            <img :src="'https://www.google.com/s2/favicons?domain=' + login.website" class="flex-shrink-0 h-5 w-5" alt="">
                            <p class="text-gray-500 truncate group-hover:text-gray-900">
                              {{ login.website }}
                            </p>
                          </a>
                        </div>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <div v-on:click="copyToClickboard(idx, 'username')" class="flex">
                          <a href="#" class="group inline-flex space-x-2 truncate text-sm mx-auto">
                          {{login.username}}
                          <svg class="flex-shrink-0 h-5 w-5 ml-2 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                          </svg>
                          </a>
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                        <div v-on:click="copyToClickboard(idx, 'password')" class="flex">
                          <a href="#" class="group inline-flex space-x-2 truncate text-sm mx-auto">
                          ********
                          <svg class="flex-shrink-0 h-5 w-5 ml-2 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                          </svg>
                          </a>
                        </div>
                      </td>

                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span v-if="Number.isInteger(login.password_strength) && login.password_strength < 1" class="inline-flex items-center mx-auto px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          Meget svagt
                        </span>
                        <span v-else-if="login.password_strength == 2" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          Mellem
                        </span>
                        <span v-else-if="login.password_strength == 3" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          Godt
                        </span>
                        <span v-else-if="login.password_strength == 4" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Højt
                        </span>
                        <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          ?
                        </span>
                      </td>
                    </tr>

                    <!-- More items... -->
                  </tbody>
                </table>
                <!-- Pagination -->
                <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                  <div class="hidden sm:block ">
                    <p class="text-sm text-gray-700">
                      Viser
                      <span v-if="this.logins" class="font-medium">{{this.logins.length}}</span>
                      resultater
                    </p>
                  </div>
                  <div class="flex-1 flex justify-between sm:justify-end">
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

     
</template>

<script>
// @ is an alias to /src
import UserMenu from '@/components/userMenu.vue';

export default {
  name: 'Home',
  components: {
    UserMenu
    
  },
  data() {
    return {
      grettingMessage: null,
      logins: null,
      errors: [],
      isLoading: {
        overview: false,
        table: true,
      },
    } 
  },

  created() {
    this.greeting();
    this.getLogins();
  },
  beforeRouteUpdate() {
    this.greeting();
  },
  methods: {
    copyToClickboard (idx, type){
      if (type == 'password') {
        var data = this.logins[idx].password
        navigator.clipboard.writeText(data)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch(err => {
          // This can happen if the user denies clipboard permissions:
          console.error('Could not copy text: ', err);
        });
      }else if (type == 'username') {
        data = this.logins[idx].username
        navigator.clipboard.writeText(data)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch(err => {
          // This can happen if the user denies clipboard permissions:
          console.error('Could not copy text: ', err);
        });
      }

    },
    greeting () {
      var thehours = new Date().getHours();
      var nat = 'God nat'
      var morgen = 'God morgen'
      var formiddag = 'God formiddag'
      var eftermiddag = 'God eftermiddag'
      var aften = 'God aften'

      if (thehours >= 23 && thehours < 6) {
        this.grettingMessage = nat; 
      } else if (thehours >= 6 && thehours < 9) {
        this.grettingMessage = morgen; 
      } else if (thehours >= 9 && thehours < 12) {
        this.grettingMessage = formiddag; 
      } else if (thehours >= 12 && thehours < 18) {
        this.grettingMessage = eftermiddag; 
      } else if (thehours >= 18 && thehours < 23) {
        this.grettingMessage = aften; 
      }
      console.log(this.greetingMessage)
    },
    async getLogins () {
      try {
        const response = await this.$axios.get('http://localhost:8000/api/user/5d2a9d55-262b-4cf3-bee1-e523e83a25cc')
        this.logins = response.data.logins
        console.log(response.data);
        this.isLoading.table = false;
      } catch (err) {
        this.errors.push(err)
      }
    }
  }
}
</script>