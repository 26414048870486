<template>
<main class="bg-gray-100 flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last" tabindex="0">
    <!-- Start main area-->
    <router-view></router-view>
    <!-- End main area -->
</main>
<aside class="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
    <div class="px-6 pt-6 pb-4">
        <h2 class="text-lg font-medium text-gray-900">Directory</h2>
        <p class="mt-1 text-sm text-gray-600">
          {{users.length}} ansatte
        </p>

    </div>
<!-- Directory list -->
    <nav class="flex-1 min-h-0 overflow-y-auto" aria-label="User directory">
            <div class="relative">
            <ul 
            v-if="users && users.length"
            class="relative z-0 divide-y divide-gray-200">

                <li v-for="(user) in users" :key="user" >
                <router-link  :to="{ name: 'user', params: { uuid: user.uuid }}">
                    <div class="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
                    <div class="flex-shrink-0">
                        <img v-if="user.avatar" class="h-10 w-10 rounded-full" :src="user.avatar" alt="">
                        <img v-if="!user.avatar" class="h-10 w-10 rounded-full" src="https://www.gravatar.com/avatar/" alt="">
                    </div>
                    <div class="flex-1 min-w-0">
                        <a class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        <p class="text-sm font-medium text-gray-900 text-left">
                            {{user.name}}
                        </p>
                        <p class="text-sm text-gray-500 truncate text-left">
                            {{ user.email}}
                        </p>
                        </a>
                    </div>
                    </div>
                </router-link>
                </li>

            </ul>
            </div>
    </nav>
</aside>
</template>

<script>
import apisdk from '@/api/index'

export default {

    data() {
        return {
            users: [],
            errors: [],
            metainfo: {
            base_url: process.env.VUE_APP_BASE_URL
            },
        }
    },
    methods: {
    },
    
    // Fetches users when the component is created.
    created() {
        apisdk.getUsers().then((response) =>{
            this.users = response;
            console.log(response)
        }).catch((error) => { 
            console.log("err: " + error)
        })
    },
}
</script>