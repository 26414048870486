// Vuejs core
import { createApp } from 'vue'
import App from './App.vue'

// Vuejs Router
import router from './router'

// Vuejs Axios
import axios from 'axios'
// import VueAxios from 'vue-axios'


// Vuex stat management
import store from "./vuex"

/* Tailwindcss */
import './assets/style/tailwind.css'


const app = createApp(App)
app.use(router).use(store).mount('#app')
app.config.globalProperties.$axios = axios;
