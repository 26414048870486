import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import Users from '../pages/users/users.vue'
import UserLayout from '../pages/users/user-layout.vue'
import NewLogin from '../pages/NewLogin.vue'

import MyLogins from '@/pages/my-logins/my-logins.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },  
  {
    path: '/mine-logins',
    name: 'MyLogins',
    component: MyLogins
  },
  {
    path: '/new/login',
    name: 'NewLogin',
    component: NewLogin,
    children: [
      {
        path: ':uuid',
        name: 'NewLoginUuid',
        component: NewLogin,
      }
    ]
  },
  {
    path: '/medarbejder-logins',
    name: 'users',
    component: Users,
    children: [
      {
        path: ':uuid',
        name: 'user',
        component: UserLayout,
      }
    ]
  },
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
