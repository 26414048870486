<template>
<main class="bg-gray-100 flex-1 relative pb-8 z-0 overflow-y-auto ">
    <user-menu />
    <form class="mt-8" id="new-expense" v-on:submit.prevent="submitForm">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="bg-white text-left shadow overflow-hidden sm:rounded-lg ">
        <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
            Opret login
            </h3>
        </div>
        <div class="border-t border-gray-200">
            <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class=" p-2 text-sm font-medium text-gray-500">
                Website
                </dt>
                <dd class=" text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input v-model="form.website" type="text" name="website" id="website" class="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="test.com">
                </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class=" p-2 text-sm font-medium text-gray-500">
                Username
                </dt>
                <dd class=" text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input v-model="form.username" type="text" name="username" id="username" class="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Username">
                </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="p-2 text-sm font-medium text-gray-500">
                Password
                </dt>
                <dd class=" text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input v-model="form.password" type="password" name="password" id="password" class="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="********">
                </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="p-2 text-sm font-medium text-gray-500">
                To Factor Auth
                </dt>
                <dd class=" text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input v-model="form.tfac" type="text" name="tfac" id="tfac" class="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="123 456">
                </dd>
            </div>
            <div v-if="!form.user_uuid || !user_uuid" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class=" p-2 text-sm font-medium text-gray-500">
                Bruger
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <select  v-model="form.user_uuid" id="user_uuid" name="user_uuid" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option v-for="(user) in users" :key="user" :value="user.uuid">{{ user.name }}</option>
                  </select>
                </dd>
            </div>

            <div v-else >
              <select v-model="form.user_uuid" id="user_uuid" name="user_uuid" class="hidden mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option :value="uuid" selected></option>
              </select>
            </div>
            </dl>
        </div>
        <div class=" border-t border-gray-200 px-4 py-5 sm:px-6">
            <button class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Opret Login
            </button>
        </div>
        </div>
    </div>
    </form>
</main>

</template>

<script>
import UserMenu from '@/components/userMenu.vue'
export default {
  components: {
    UserMenu
  },
  data () {
    return {
      form:{
      user_uuid: this.$route.params.uuid
      },
      users: [],
      errors: [],
      user_uuid: this.$route.params.uuid
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    submitForm(){
      this.$axios.post('http://127.0.0.1:8000/api/create-password', this.form)
            .then((res) => {
                //Perform Success Action
                console.log(res)
                this.$router.push("/");
            })
            .catch((error) => {
                // error.response.status Check status code
                console.log(error)
            }).finally(() => {
                //Perform action in always
            });
    },
    async getUsers () {
      try {
        const response = await this.$axios.get('http://localhost:8000/api/users')
        this.users = response.data
        console.log(response.data);
      } catch (err) {
        this.errors.push(err)
      }
    }
  }
}
</script>