import Axios from 'axios'
import store from '@/vuex/index'

const API_URL = 'http://localhost:8000/api';

export function getUsers() {
    console.log(store.state.appMetaData)

    return new Promise((resolve, reject) => {
        Axios.get(`${API_URL}/users`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch(reject);
    });
}

export function getUserById(id) {
    console.log(store.state.appMetaData)

    return new Promise((resolve, reject) => {
        Axios.get(`${API_URL}/users/${id}`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch(reject);
    });
}

export default { 
    getUsers, 
    getUserById
};